import { application } from "./application"

import CollapseController from "./collapse_controller"
Stimulus.register("collapse", CollapseController)

import DialogController from "./dialog_controller"
Stimulus.register("dialog", DialogController)

import DropdownController from "./dropdown_controller"
Stimulus.register("dropdown", DropdownController)

import PlayerController from "./player_controller"
Stimulus.register("player", PlayerController)

import PlayableController from "./playable_controller"
Stimulus.register("playable", PlayableController)

import TabsController from "./tabs_controller"
Stimulus.register("tabs", TabsController)

import PlayingNowIndicatorController from "./playing_now_indicator_controller"
Stimulus.register("playing-now-indicator", PlayingNowIndicatorController)

import CarouselController from "./carousel_controller"
Stimulus.register("carousel", CarouselController)

import PlaylistController from "./playlist_controller"
Stimulus.register("playlist", PlaylistController)

import FavouriteController from "./favourite_controller"
Stimulus.register("favourite", FavouriteController)

import BookingRequestController from "./booking_request_controller"
Stimulus.register("booking-request", BookingRequestController)

import SelectAllController from "./select_all_controller"
Stimulus.register("select-all", SelectAllController)

import SearchFiltersController from "./search_filters_controller"
Stimulus.register("search-filters", SearchFiltersController)

import BadgeController from "./badge_controller"
Stimulus.register("badge", BadgeController)

import AddToPlaylistController from "./add_to_playlist_controller"
Stimulus.register("add-to-playlist", AddToPlaylistController)

import AddToPlaylistPopupController from "./add_to_playlist_popup_controller"
Stimulus.register("add-to-playlist-popup", AddToPlaylistPopupController)

import CreatePlaylistController from "./create_playlist_controller"
Stimulus.register("create-playlist", CreatePlaylistController)

import MapsController from "./maps_controller"
Stimulus.register("maps", MapsController)

import NotificationController from "./notification_controller"
Stimulus.register("notification", NotificationController)

import ThemeController from "./theme_controller"
Stimulus.register("theme", ThemeController)
